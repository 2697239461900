// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---my-theme-src-pages-404-tsx": () => import("./../../../../my-theme/src/pages/404.tsx" /* webpackChunkName: "component---my-theme-src-pages-404-tsx" */),
  "component---my-theme-src-templates-homepage-template-tsx": () => import("./../../../../my-theme/src/templates/HomepageTemplate.tsx" /* webpackChunkName: "component---my-theme-src-templates-homepage-template-tsx" */),
  "component---my-theme-src-templates-page-template-tsx": () => import("./../../../../my-theme/src/templates/PageTemplate.tsx" /* webpackChunkName: "component---my-theme-src-templates-page-template-tsx" */),
  "component---my-theme-src-templates-project-template-tsx": () => import("./../../../../my-theme/src/templates/ProjectTemplate.tsx" /* webpackChunkName: "component---my-theme-src-templates-project-template-tsx" */),
  "component---my-theme-src-templates-projects-template-tsx": () => import("./../../../../my-theme/src/templates/ProjectsTemplate.tsx" /* webpackChunkName: "component---my-theme-src-templates-projects-template-tsx" */),
  "component---my-theme-src-templates-tag-page-template-tsx": () => import("./../../../../my-theme/src/templates/TagPageTemplate.tsx" /* webpackChunkName: "component---my-theme-src-templates-tag-page-template-tsx" */),
  "component---my-theme-src-templates-year-page-template-tsx": () => import("./../../../../my-theme/src/templates/YearPageTemplate.tsx" /* webpackChunkName: "component---my-theme-src-templates-year-page-template-tsx" */)
}

